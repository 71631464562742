import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { ChangePasswordModule } from './change-password/change-password.module';
// import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { LoginModule } from './login/login.module';
import { SignUpModule } from './signup/signup.module';


@NgModule({
  declarations: [],
  imports: [
CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // ForgotPasswordModule,
    // LoginModule,
    // SignUpModule,
    ChangePasswordModule,
  ],
  providers: [],

})
export class AuthModule {}
