import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CookieModule } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';
import { CustomAuthService } from '../@auth/auth-module.service';
import { InterceptorProviders } from './@interceptors';
import { CustomApiService } from './@services/api.service';
import { CommonService } from './@services/common.service';
import { DataUpdateService } from './@services/data.service';
import { CustomToastService } from './@services/toast.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AuthModule } from '../@auth/auth.module';

@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    RouterModule,
    CookieModule.forRoot(),
    AuthModule
  ],
  exports: [CommonModule, ToastrModule],
  declarations: [],
  providers: [
    CommonService,
    CustomAuthService,
    CustomToastService,
    CustomApiService,
    DataUpdateService,
    InterceptorProviders,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [],
    } as ModuleWithProviders<any>;
  }
}
