import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DataUpdateService {
  updateDetails = new Subject<boolean>();

  public modalPopupSubject = new BehaviorSubject<string>('');
  ModalPopupSubjectObservable = this.modalPopupSubject.asObservable();

  public updateChange = new BehaviorSubject<boolean>(false);
  updateChangeObservable = this.updateChange.asObservable();

  public changeLogin = new BehaviorSubject<boolean>(false);
  changeLoginObservable = this.changeLogin.asObservable();

  public openLogin = new BehaviorSubject<boolean>(false);
  loginRequestObservable = this.openLogin.asObservable();

  public openSignup = new BehaviorSubject<boolean>(false);
  signupRequestObservable = this.openSignup.asObservable();
  public changeProfileInfo = new BehaviorSubject<boolean>(false);
  changeProfileInfoObservable = this.changeProfileInfo.asObservable();

  constructor() { }

  changeModalPopup(value): void {
    this.modalPopupSubject.next(value);
  }

  changeValue(value): void {
    this.updateChange.next(value);
  }

  changeLoggedIn(value): void{
    this.changeLogin.next(value);
  }

  openLoginRequest(value): void {
    this.openLogin.next(value);
  }

  openSignRequest(value): void {
    this.openSignup.next(value);
  }
  changeProfile(value){
    this.changeProfileInfo.next(value);
  }

}

