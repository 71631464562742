export const FAKE_API = false;

// stag paths
// export const BASE_URL = 'http://localhost:3001';
// export const BASE_URL = 'http://18.191.244.74:3000';
// export const BASE_URL_LOCAL = 'http://192.168.0.126:3000';
// // export const IMAGE_URL = 'https://app-transfer.com:3006/api/image?filename=';
// export const IMAGE_URL = 'http://18.191.244.74:3000/api/user/upload/local';

// // export const LARGE_IMAGE_URL = 'https://storage.googleapis.com/kctickets-bucket/uploads/images/';
// // export const MEDIUM_IMAGE_URL = 'https://storage.googleapis.com/kctickets-bucket/uploads/images/medium/';
// // export const SMALL_IMAGE_URL = 'https://storage.googleapis.com/kctickets-bucket/uploads/images/small/';
// // export const SOCKET_BASE_URL = 'https://app-transfer.com:3006';

// export const IMAGE_SHOW_URL ="http://18.191.244.74:3000/api/user/local/file?filename="


// // // // // // // // // live ownchecks URLs
// export const BASE_URL ='https://authentication.resaleforce.com:3000';
//   export const BASE_URL_LOCAL = 'http://192.168.0.126:3000';
// // // // export const IMAGE_URL = 'https://app-transfer.com:3006/api/image?filename=';
//   export const IMAGE_URL = 'https://authentication.resaleforce.com:3000/api/user/upload/aws';
// // export const IMAGE_URL = 'https://authentication.resaleforce.com:3000/api/user/upload/aws';
// export const STRIPE_API_KEY = 'pk_live_h8i1yvpNzeq0QMxPKhJ2Faa1'


// // // // // // // // staging ownchecks URLs RESALE FORCE STAGING
export const BASE_URL = ' https://staging.resaleforce.com:3001';
export const BASE_URL_LOCAL = 'http://192.168.0.126:3000';
// // export const IMAGE_URL = 'https://app-transfer.com:3006/api/image?filename=';
 export const IMAGE_URL = ' https://staging.resaleforce.com:3001/api/user/upload/aws';
// export const IMAGE_URL = ' http://3.132.42.174:8081:3000/api/user/upload/aws';
export const STRIPE_API_KEY = 'pk_test_bB2uPxkwStAYDXGllflyop5c'


// // export const LARGE_IMAGE_URL = 'https://storage.googleapis.com/kctickets-bucket/uploads/images/';
// // export const MEDIUM_IMAGE_URL = 'https://storage.googleapis.com/kctickets-bucket/uploads/images/medium/';
// // export const SMALL_IMAGE_URL = 'https://storage.googleapis.com/kctickets-bucket/uploads/images/small/';
// // export const SOCKET_BASE_URL = 'https://app-transfer.com:3006';

// // export const IMAGE_SHOW_URL ="https://ownchecks.com:3000/api/user/local/file?filename="
// export const IMAGE_SHOW_URL = 'https://ownchecks.s3.us-east-2.amazonaws.com/Uploads/Images/Original/';



// export const GOOGLE_API_KEY =
//   '';




// // // // // //staging ownchecks URLs
// export const BASE_URL = 'http://3.132.42.174:3001';
// export const BASE_URL_LOCAL = 'http://192.168.0.126:3000';
// export const IMAGE_URL = 'http://3.132.42.174:3001/api/user/upload/aws';
export const IMAGE_SHOW_URL = 'https://ownchecks.s3.us-east-2.amazonaws.com/Uploads/Images/Original/';
export const GOOGLE_API_KEY =
  '';



export const BS_CONFIG = {
  dateInputFormat: 'YYYY-MM-DD',
  showWeekNumbers: false,
  isAnimated: true,
  adaptivePosition: true,
  displayOneMonthRange: true,
};

export const MODAL_CONFIG_OPTIONS = {
  centered: true,
};

export const  LIVING_REASONS = [
  'I was visiting and don’t plan on returning to the area.',
  'I never found an event or attraction I was interested in.',
  'I think the website is hard to navigate.',
  'I think it is too difficult create and post events or attractions.',
  'I could not get the website to work well or loads too slowly.',
  'I use a different website similar to this one.',
  'I had a bad experience with a vendor and/or the event or attraction listing.',
  'I have other reasons and will explain.',
    ];

