import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { BASE_URL } from 'src/app/@core/@utills/constant';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class CommonService {
  public serverURL = BASE_URL + '/api/';
  backOnAuthentication:boolean=false;
  noWhitespaceValidator: ValidatorFn;
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private router: Router, private cookie: CookieService,
  ) { }

  getJsonCookie(name) {
    let data = this.cookie.get(name);
    if (data) return JSON.parse(data);
    return null;
  }
  getCookie(name) {
    return this.cookie.get(name);
  }
  setCookie(name, value) {
    if (this.isBrowser()) {
      return this.cookie.put(name, value);
    }
  }
  setStrCookie(name, value) {
    if (this.isBrowser()) {
      return this.cookie.put(name, JSON.stringify(value));
    }
  }


  isBrowser() {
    if (isPlatformBrowser(this._platformId)) {
      return true;
    }
    return false;
  }

  navigate(url) {
    this.router.navigateByUrl(url);
  }
  isAutherize(): boolean {
    if (localStorage.getItem('session')) {
      return true;
    } else {
      return false;
    }
  }

}
