import {API_TYPE} from './api-type';
export const LOGIN = {
    method: API_TYPE.POST,
    endpoint: 'user/login',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const SIGNUP = {
    method: API_TYPE.POST,
    endpoint: 'user/signup',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const CONTACTUS = {
    method: API_TYPE.POST,
    endpoint: 'contact-us',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const FORGOT_PASSWORD = {
    method: API_TYPE.POST,
    endpoint: 'user/forgot/password',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const VERIFY_PHONE = {
    method: API_TYPE.POST,
    endpoint: 'verify/phone',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const RESEND_VERIFY_PHONE = {
    method: API_TYPE.POST,
    endpoint: 'resend/verification-phone',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const CHANGE_PASSWORD = {
    method: API_TYPE.PUT,
    endpoint: 'user/change/password',
    fake: true,
    fakeResponsePath: 'assets/json/login'
};
export const RESET_PASSWORD = {
    method: API_TYPE.POST,
    endpoint: 'user/reset/password',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const PROFILE_PUT = {
    method: API_TYPE.PUT,
    endpoint: 'profile',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const CONTACT_INFO_PROFILE_PUT = {
  method: API_TYPE.PUT,
  endpoint: 'user/contactInfo',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const PERSONAL_INFO_PROFILE_PUT = {
  method: API_TYPE.PUT,
  endpoint: 'user/profile',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const BILLING_INFO_PROFILE_PUT = {
  method: API_TYPE.PUT,
  endpoint: 'user/billingAddress',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const PROFILE_GET = {
    method: API_TYPE.GET,
    endpoint: 'user/profile',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const CONTENT_GET = {
    method: API_TYPE.GET,
    endpoint: 'admin/content',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};

export const RESEND_VERIFY_EMAIL = {
    method: API_TYPE.PUT,
    endpoint: 'user/resend-verify-email',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const PROFILE_DEACTIVATE = {
    method: API_TYPE.POST,
    endpoint: 'user/deactivate/account',
    isFake: false,
    fakeResponsePath: 'assets/json/login'
};
export const SETUP_INTENT = {
  method: API_TYPE.POST,
  endpoint: 'sneaker/setupIntent',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const CREATE_PAYMENT_INTENT = {
  method: API_TYPE.POST,
  endpoint: 'sneaker/createPaymentIntent',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const SUBMIT_REVIEW = {
  method: API_TYPE.POST,
  endpoint: 'user/review',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const ADD_CARD = {
  method: API_TYPE.POST,
  endpoint: 'sneaker/addCard',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const GET_CARDS = {
  method: API_TYPE.GET,
  endpoint: 'sneaker/getCards',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const DELETE_CARDS = {
  method: API_TYPE.DELETE,
  endpoint: 'sneaker/deleteCard',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const ADD_PROMO_CODE = {
  method: API_TYPE.POST,
  endpoint: 'sneaker/promotionCode',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const CONFIRM_ORDER = {
  method: API_TYPE.POST,
  endpoint: 'sneaker/confirmOrder',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const SEARCH_SNEAKERS = {
  method: API_TYPE.POST,
  endpoint: 'sneaker/search',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const SNEAKERS_MANUAL_REVIEW = {
  method: API_TYPE.POST,
  endpoint: 'sneaker/manualReview',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const CANCEL_ORDER = {
  method: API_TYPE.PUT,
  endpoint: 'sneaker/cancelOrder',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const UPLOAD_IMAGE = {
  method: API_TYPE.POST,
  endpoint: 'upload/local',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const AUTO_AUTHENTICATE_ORDER = {
  method: API_TYPE.POST,
  endpoint: 'sneaker/autoAuthentication',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const GET_AUTHENTICATED_ORDERS = {
  method: API_TYPE.GET,
  endpoint: 'sneaker/authenticatedOrders',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const GET_IN_PROGRESS_ORDERS = {
  method: API_TYPE.GET,
  endpoint: 'sneaker/inProgress',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const GET_FAILED_ORDERS = {
  method: API_TYPE.GET,
  endpoint: 'sneaker/Failed',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const GET_PRODUCT_DETAILS = {
  method: API_TYPE.GET,
  endpoint: 'sneaker/authenticationStatus',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const GET_PAYMENT_TOKEN = {
  method: API_TYPE.GET,
  endpoint: 'sneaker/paymentToken',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const GET_FIRST_FREE_PAYMENT = {
  method: API_TYPE.GET,
  endpoint: 'sneaker/firstPayment',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};
export const GET_CLIENT_SECRET = {
  method: API_TYPE.POST,
  endpoint: 'sneaker/createPaymentIntent',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};

export const LOGIN_AS_USER = {
  method: API_TYPE.POST,
  endpoint: 'admin/loginAsUser',
  isFake: false,
  fakeResponsePath: 'assets/json/login',
};
export const NOTIFICATIONS_GET = {
  method: API_TYPE.GET,
  endpoint: 'user/notificationList',
  isFake: false,
  fakeResponsePath: 'assets/json/login'
};



