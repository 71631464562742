import {
  HttpClient,

  HttpHeaders
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'angularx-social-login';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CommonService } from 'src/app/@core/@services/common.service';
import { BASE_URL, BASE_URL_LOCAL, IMAGE_URL } from 'src/app/@core/@utills/constant';
import { API_TYPE } from '../@utills/api-type';
import { DataUpdateService } from './data.service';
import { CustomToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class CustomApiService {
  public serverURL = BASE_URL + '/api/';
  // public serverURL = BASE_URL_LOCAL + '/api/';
  noWhitespaceValidator: ValidatorFn;
  constructor(
    private http: HttpClient,
    private toastService: CustomToastService,
    private dataService: DataUpdateService,
    private cookie: CookieService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private router: Router
  ) { }

  // getHeader() {
  //   const session = JSON.parse(localStorage.getItem('session'));
  //   const headers = new HttpHeaders()
  //     .set('Authorization', session ? session.token : '')
  //     .set('Content-Type', 'application/json');
  //   console.log(headers);

  //   return headers;
  // }

  getHeader() {
    let token = this.cookie.get('bearer') || "";
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
    });
    return headers;
}
  getUrl(url, isFake?: any) {
    if (isFake) {
      return 'https://app-transfer.com:3006/' + url;
    }
    return this.serverURL + url;
  }

  checkProfileCompleted() {
    const session = JSON.parse(localStorage.getItem('session'));
    const is_profile_completed = session.user.is_profile_completed;
    if (is_profile_completed) {
      return true;
    } else {
      return false;
    }
  }

  checkStripeConnected() {
    const session = JSON.parse(localStorage.getItem('session'));
    const is_stripe_connected = session.user.is_stripe_connected;
    if (is_stripe_connected) {
      return true;
    } else {
      return false;
    }
  }



  httpRequest(requestConfig, data?): Observable<any> {
    if (requestConfig.method == API_TYPE.GET) {
      return this.http.get(this.getUrl(requestConfig.endpoint, requestConfig.isFake), {
        params: data,
      });
    } else if (requestConfig.method == API_TYPE.POST) {
      return this.http.post(this.getUrl(requestConfig.endpoint, requestConfig.isFake), data, {
        headers: this.getHeader(),
      });
    } else if (requestConfig.method == API_TYPE.PUT) {
      return this.http.put(this.getUrl(requestConfig.endpoint, requestConfig.isFake), data, {
        headers: this.getHeader(),
      });
    } else if (requestConfig.method == API_TYPE.DELETE) {
      const data1 = {
        body: data,
        headers: this.getHeader(),
      };
      return this.http.delete(this.getUrl(requestConfig.endpoint, requestConfig.isFake), data1);
    }
  }

  unauthorized() {
    this.toastService.showError('Please login to Continue.', 'Unauthorized');
    localStorage.clear();
    this.router.navigate(['']);
  }

  isAutherize(): boolean {
    if (localStorage.getItem('session')) {
      HttpClient;
      return true;
    } else {
      return false;
    }
  }


  logout() {
    return new Promise((resolve, error) => {
        let auth: any = this.getCookie('auth');
        if (auth == 0) {

        }
        this.delAllCookie().then((res) => {
            this.toastService.showSuccess('User Logged out Successfully', 'Logged Out');
        });
        localStorage.clear();
        this.dataService.changeLoggedIn(false);
        this.router.navigate(['']);
        resolve(true)
    })
}

  get checkCookie() {
    let cookie = this.cookie.getAll()
    return cookie
  }
  i=0;
  delAllCookie() {
    return new Promise((res, err) => {
      let cookies = this.cookie.getAll();
      this.i++
      for (const key in cookies) {
        if (cookies.hasOwnProperty(key)) {
          this.delCookie(key)
        }
      }
      this.cookie.removeAll();
      if (Object.keys(this.checkCookie).length == 0) {
        res(true)
      } else {
        if (this.i <= 100) {
          this.delAllCookie()
        } else {
          err(true)
        }
      }
    })
  }
  delCookie(name) {
    return this.cookie.remove(name)
  }

  uploadMedia(request:FormData) {
    // const request = new FormData();
    // request.append('file', data);

    return this.http.post(IMAGE_URL,request,{headers: new HttpHeaders().set('skipHeader', 'true'),});
  }

  setCookie(name: string, value: string) {
    this.cookie.put(name, value, {
      path: '/',
    });
  }

  setStrCookie(name: string, value) {
    value = JSON.stringify(value);
    this.cookie.put(name, value, {
      path: '/',
    });
  }

  getCookie(name: string) {
    return this.cookie.get(name);
  }

  getJSONCookie(name: string) {
    const val = this.cookie.get(name);
    return JSON.parse(val);
  }

  deleteAllCookies() {
    this.cookie.removeAll({
      path: '/',
    });
  }
  setTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data.title) {
              return child.snapshot.data.title;
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.setTitleValue(data);
        }
      });
  }

  setTitleValue(val: string) {
    this.titleService.setTitle('Potswork : ' + val);
  }
}
